import { gql } from '@apollo/client';

/* graphql queries */
export const GET_POSTS_BY_CATEGORY = gql`
    query GetPostsByCategory($category: String!) {
        posts(where: { categoryName: $category }) {
            nodes {
                title
                slug
                date
                databaseId
                excerpt
                featuredImage {
                    node {
                        altText
                        sourceUrl(size: MEDIUM)
                    }
                }
                categories {
                    nodes {
                        name
                    }
                }
            }
        }
    }
`;

export const GET_POST_BY_SLUG = gql`
    query GetPost($slug: ID!) {
        post(id: $slug, idType: SLUG) {
            title
            content
            date
            categories {
                nodes {
                    slug
                    name
                }
            }
        }
    }
`;

/* Form */
export const FORM_STATUS = {
    success: 'SUCCESS',
    error: 'ERROR',
    submitting: 'SUBMITTING',
    initial: 'INITIAL',
};

export const EMAIL_REGEX =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
