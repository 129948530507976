import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './PostCard.module.scss';

const PostCard = ({ post }) => {
    const { slug, featuredImage, title, excerpt, categories } = post;

    const postCategory = categories.nodes[0].name;

    return (
        <li className={postCategory === 'proyectos' ? `${styles.postCard} ${styles.project}` : styles.postCard }>
            <Link to={`/post/${slug}`}>
                <article /* className={postCategory === 'proyectos' ? styles.project : null } */>
                    <img
                        src={featuredImage.node.sourceUrl}
                        alt={featuredImage.node.altText}
                        className={styles.postImg}
                    />
                    <h3 className={styles.postTitle}>{title}</h3>
                    {postCategory !== 'proyectos' ? (
                        <div
                            className={styles.postExcerpt}
                            dangerouslySetInnerHTML={{ __html: excerpt }}
                        />
                    ) : null}
                </article>
            </Link>
        </li>
    );
};

export default PostCard;
