import React from 'react';
import styles from './Loader.module.scss';
import { ProgressBar } from 'react-loader-spinner';

const Loader = () => {
    return (
        <div className={styles.loaderContainer}>
        <p>Cargando...</p>
        <ProgressBar
            height="80"
            width="80"
            ariaLabel="progress-bar-loading"
            wrapperStyle={{}}
            wrapperClass="progress-bar-wrapper"
            borderColor="#f8a3ff"
            barColor="#443ab4"
        />
    </ div>
    );
};

export default Loader;
