import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App'; 
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import client from './lib/apollo';
import ScrollToTop from './components/scrollToTop/ScrollToTop';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
       <ApolloProvider client={client}>
          <BrowserRouter>
              <ScrollToTop />
              <App />
          </BrowserRouter>
       </ApolloProvider>
    </React.StrictMode>
);
