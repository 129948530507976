import React from 'react';
import ContactForm from '../../components/contactForm/ContactForm';
import styles from './Contact.module.scss';

const Contact = () => {
    return (
        <>
            <section>
                <h1>Contacto</h1>
                <p>
                    Envianos tu consulta por mail o completando el formulario.
                    En breve nos comunicaremos con vos.
                </p>
                <p>
                    ⟶{' '}
                    <a href="mailto:consultas@altcooperativa.com">
                        consultas@altcooperativa.com
                    </a>
                </p>
            </section>
            <section className={styles.contactForm}>
                <ContactForm />
            </section>
        </>
    );
};

export default Contact;
