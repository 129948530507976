import React from 'react';
import styles from './Home.module.scss';
import PlaceholderIMG from './assets/placeholder.png';
import Button from '../../components/button/Button';
import { useNavigate } from 'react-router-dom';

const Home = () => {
    const navigate = useNavigate();

    return (
        <>
            <section className={styles.hero}>
                <h1 className={styles.mainTitle}>ALT</h1>

                <p className={styles.description}>
                    El mundo está en constante evolución. ¡También lo está tu
                    emprendimiento! <br /> En ALT tenemos las herramientas para
                    diseñar y desarrollar el sitio web que necesitas.
                </p>
                <Button handleClick={() => navigate('/contacto')}>
                    ¡QUIERO UN SITIO!
                </Button>
            </section>
            <section className={styles.about}>
                <h2 className={styles.secondaryTitle}>
                    COOPERATIVA {/* <br /> ALTERNATIVA <br /> TRANS */}
                </h2>
                <img src={PlaceholderIMG} alt="ALT Cooperativa" />

                <p>
                    Somos una cooperativa de trabajo especializada en el diseño
                    y desarrollo de soluciones web, conformada por personas
                    trans y creada en Argentina.
                </p>
                <p>
                    Ofrecemos servicios de Diseño UX/UI, Desarrollo Web,
                    Community Manager, Ilustración Digital, Cursos y talleres.
                </p>
                <Button
                    handleClick={() => navigate('/servicios')}
                    variant="secondary"
                >
                    Quiero saber más
                </Button>
            </section>
        </>
    );
};

export default Home;
