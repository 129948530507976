import React from 'react';
import styles from './Footer.module.scss';
import LogoAlt from './assets/logo-alt.png';
import LogoIg from './assets/instagram.png';
import LogoLkIn from './assets/linkedin.png';
import LogoGmail from './assets/gmail.png';

const Footer = () => {
    const socialMedia = [
        {
            name: 'instagram',
            url: 'https://www.instagram.com/alt.cooperativa/',
            logo: LogoIg,
        },
        {
            name: 'linkedin',
            url: 'https://www.linkedin.com/company/altcooperativa/',
            logo: LogoLkIn,
        },
        {
            name: 'mail',
            url: 'mailto:alt.cooperativa@gmail.com',
            logo: LogoGmail,
        },
    ];

    return (
        <footer className={styles.footer}>
            <img src={LogoAlt} alt="Logo de Alt" className={styles.altLogo}/>
            <ul className={styles.socialMediaList}>
                {socialMedia.map((item, index) => (
                    <li key={`social-media-${index}`} className={styles.socialMediaItem}>
                        <a href={item.url} target="_blank" rel="noopener noreferrer">
                            <img src={item.logo} alt={`Ir al ${item.name} de Alt`} />
                        </a>
                    </li>
                ))}
            </ul>
        </footer>
    );
};

export default Footer;
