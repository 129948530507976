import React, { Route, Routes } from 'react-router-dom';
import './styles/globals.scss';
import Home from './pages/home/Home';
import About from './pages/about/About';
import Services from './pages/services/Services';
import Blog from './pages/blog/Blog';
import Contact from './pages/contact/Contact';
import Nav from './components/nav/Nav';
import SinglePost from './pages/singlePost/SinglePost';
import NotFound from './pages/notFound/NotFound';
import Footer from './components/footer/Footer';

function App() {
    return (
        <div className="App">
            <Nav />
            <main className="page-content">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/nosotres" element={<About />} />
                    <Route path="/servicios" element={<Services />} />
                    <Route path="/blog" element={<Blog />} />
                    <Route path="/post/:postSlug" element={<SinglePost />} />
                    <Route path="/contacto" element={<Contact />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </main>
            <Footer />
        </div>
    );
}

export default App;
