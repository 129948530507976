import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import styles from './Nav.module.scss';
import Logo from './logo-alt.png';
import { MdOutlineMenu } from 'react-icons/md';
import { MdClose } from 'react-icons/md';

const Nav = () => {
    const [openMenu, setOpenMenu] = useState(false);

    const handleClick = () => setOpenMenu(!openMenu);

    const closeMobileMenu = () => setOpenMenu(false);

    const menuBtnStyles = {
        color: '#010216'
    }

    const menuItems = [
        {path: '/', text: 'Inicio'},
        {path: '/servicios', text: 'Servicios'},
        {path: '/nosotres', text: 'Nosotres'},
        {path: '/blog', text: 'Novedades'},
        {path: '/contacto', text: 'Contacto'},
    ];

    return (
        <nav className={styles.nav}>
            <Link to="/" className={styles.navLogo} onClick={closeMobileMenu}>
                <img src={Logo} alt="Ir al inicio" />
            </Link>
            <button className={styles.menuButton} onClick={handleClick}>
                {openMenu ? <MdClose style={menuBtnStyles}  size={'1.5em'}/> : <MdOutlineMenu style={menuBtnStyles} size={'1.5em'}/> }
            </button>
            <ul
                className={
                    openMenu ? `${styles.menu} ${styles.openMenu}` : styles.menu
                }
            >
                {menuItems.map((item, idx) =>
                    <li key={idx}>
                        <NavLink
                            to={item.path}
                            className={({ isActive }) =>
                                isActive
                                    ? `${styles.menuLink} ${styles.active}`
                                    : styles.menuLink
                            }
                            onClick={closeMobileMenu}
                        >
                            {item.text}
                        </NavLink>
                </li>
                )}

            </ul>
        </nav>
    );
};

export default Nav;
