import React from 'react';
import { useQuery } from '@apollo/client';
import styles from './PostsList.module.scss';
import PostCard from '../postCard/PostCard';
import { GET_POSTS_BY_CATEGORY } from '../../lib/constants';
import Loader from '../loader/Loader';

const PostsList = ({ categoryName }) => {
    const { loading, error, data } = useQuery(GET_POSTS_BY_CATEGORY, {
        variables: {
            category: categoryName,
        },
    });

    if (loading) return <Loader />;

    if (error) return <p>Ups 😥...</p>;

    const postsFound = Boolean(data?.posts.nodes.length);

    if (!postsFound) {
        return <p>No se encontraron {categoryName}...</p>;
    }

    return (
        <ul className={styles.postsList}>
            {data.posts.nodes.map(post => (
                <PostCard key={post.databaseId} post={post} />
            ))}
        </ul>
    );
};

export default PostsList;
