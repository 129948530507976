import React from 'react';
import PostsList from '../../components/postsList/PostsList';
import styles from './Blog.module.scss';
import LogoIg from './assets/instagram.png';
import LogoLkIn from './assets/linkedin.png';

const Blog = () => {
    return (
        <>
            <section className={styles.hero}>
                <h1>ALT Blog</h1>
                <p>
                    Aquí compartimos todas las novedades sobre nuestra
                    cooperativa.
                </p>
                <p>Te invitamos a seguirnos también en las redes:</p>
                <a
                    href="https://www.instagram.com/alt.cooperativa/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.socialMedia}
                >
                    <img
                        src={LogoIg}
                        alt="Ir al instagram de Alt cooperativa"
                    />
                </a>
                <a
                    href="https://www.linkedin.com/company/altcooperativa/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.socialMedia}
                >
                    <img
                        src={LogoLkIn}
                        alt="Ir al Linkedin de Alt cooperativa"
                    />
                </a>
            </section>
            <section className={styles.posts}>
                <PostsList categoryName={'novedades'} />
            </section>
        </>
    );
};

export default Blog;
