import React, { useState } from 'react';
import Button from '../button/Button';
import styles from './ContactForm.module.scss';
import emailjs from '@emailjs/browser';
import { EMAIL_REGEX, FORM_STATUS } from '../../lib/constants';
import { useForm } from 'react-hook-form';

const ContactForm = () => {
    const [formStatus, setFormStatus] = useState(FORM_STATUS.initial);

    const {
        register,
        handleSubmit,
        formState: {
            errors,
            isSubmitting,
        },
        reset,
    } = useForm({
        defaultValues: {
            name: '',
            pronouns: '',
            email: '',
            message: '',
        },
    });

    const formSubmitHandler = data => {
        emailjs
            .send(
                process.env.REACT_APP_EMAILJS_SERVICE_ID,
                process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
                data,
                process.env.REACT_APP_EMAILJS_PUBLIC_KEY
            )
            .then(
                result => {
                    reset();
                    console.log(result.text);
                    setFormStatus(FORM_STATUS.success);
                },
                error => {
                    console.log(error.text);
                    setFormStatus(FORM_STATUS.error);
                }
            );
    };

    return (
        <>
            <form
                className={styles.form}
                onSubmit={handleSubmit(formSubmitHandler)}
            >
                <label htmlFor="name" className={styles.label}>
                    Tu nombre*:
                </label>
                <input
                    {...register('name', {
                        required: 'Por favor ingresá tu nombre.',
                    })}
                    type="text"
                    className={styles.formField}
                />
                {errors.name ? <p className={styles.errorMessage}>{errors.name?.message}</p> : null}

                <label htmlFor="pronouns" className={styles.label}>
                    Pronombre/s que usas (por ejemplo ella, elle, el):
                </label>
                <input
                    {...register('pronouns')}
                    type="text"
                    className={styles.formField}
                />

                <label htmlFor="email" className={styles.label}>
                    Tu e-mail*:
                </label>
                <input
                    {...register('email', {
                        required: 'Por favor ingresá un e-mail.',
                        pattern: {
                            value: EMAIL_REGEX,
                            message: 'El e-mail no es válido.',
                        },
                    })}
                    type="text"
                    className={styles.formField}
                />
                {errors.email ? <p className={styles.errorMessage}>{errors.email?.message}</p> : null}

                <label htmlFor="message" className={styles.label}>
                    Mensaje*:
                </label>
                <textarea
                    {...register('message', {
                        required: 'Por favor escribí un mensaje.',
                    })}
                    className={`${styles.formField} ${styles.textarea}`}
                ></textarea>
                {errors.message ? <p className={styles.errorMessage}>{errors.message?.message}</p> : null}

                <Button variant="outline" type="submit" disabled={isSubmitting}>
                    Enviar
                </Button>

                 {formStatus !== FORM_STATUS.initial ? (
                    <p className={styles.errorMessage}>
                        {formStatus === FORM_STATUS.success
                            ? '¡Tu mensaje fue enviado! Gracias por ponerte en contacto, a la bravedad nos estaremos comunicando 💜'
                            : formStatus === FORM_STATUS.error
                            ? 'Ups! Hubo un error. Por favor, intentá de nuevo más tarde o escribinos a consultas@altcooperativa.com'
                            : null}
                    </p>
                ) : null}
            </form>
        </>
    );
};

export default ContactForm;
