import React from 'react';
/* import styles from './About.module.scss'; */
import { useNavigate } from 'react-router-dom';
import Button from '../../components/button/Button';

const About = () => {
    const navigate = useNavigate();

    return (
        <>
            <section>
                <h1>¿Por qué somos “Alternativa Laboral Trans”?</h1>
                <p>
                    Nuestra comunidad, lamentablemente, es una de las más
                    vulneradas del mundo. En la Argentina tan sólo el 18% de la
                    población trans ha tenido acceso a un empleo formal y la
                    expectativa de vida es de 35 años en promedio. Nosotres
                    queremos ayudar a cambiar eso creando empleo de calidad para
                    nuestres hermanes.
                </p>
            </section>

            <section>
                <h2>Historia</h2>

                <p>
                    La cooperativa inició en 2020 como equipo de trabajo pero
                    fue registrada formalmente en el INAES en 2021, a partir de
                    entonces se convirtió en la primera cooperativa de
                    tecnología completamente integrada por personas trans
                    matriculada en Argentina.
                </p>

                <p>
                    En estos dos años hemos hecho numerosos trabajos de diseño
                    UX/UI y desarrollo web (sitios institucionales, blogs,
                    e-commerce, portfolios). Además, participamos de charlas,
                    talleres y brindamos formaciones en diseño y desarrollo web.
                </p>

                <p>
                    Durante 2022 nos incorporamos a FACTTIC, la Federación
                    Argentina de Cooperativas de Trabajo de Tecnología,
                    Innovación y Conocimiento.
                </p>

                <p>
                    Aspiramos a seguir sumando personas a nuestro equipo y
                    brindando posibilidades de trabajo para la comunidad trans.
                </p>
            </section>

            <section className="outline">
                <h2>Equipo</h2>

                <p>
                    Actualmente la Cooperativa está conformada por seis
                    asociades, pero también trabajamos en comunidad, capacitando
                    y sumando colaboradores.
                </p>

                <article>
                    <h3>Equipo de Desarrollo 💻🛠</h3>

                    <ul>
                        <li>Vittorio Tristan Taddeo Mazar</li>
                        <li>Jus Recondo</li>
                        <li>Tomi Silva</li>
                    </ul>
                </article>

                <article>
                    <h3>Equipo de Diseño UX/UI 🖌💡</h3>

                    <ul>
                        <li>Elena Ficher</li>
                        <li>Aslan Joel Santrowichi</li>
                        <li>Luca Zúñiga Brenes</li>
                    </ul>
                </article>
                <Button handleClick={() => navigate('/contacto')} variant="secondary" customClassName="centerBtn">
                    ¡QUIERO UN SITIO!
                </Button>
            </section>
        </>
    );
};

export default About;
