import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/button/Button';

const NotFound = () => {
    const navigate = useNavigate();

    return (
        <>
            <section>
                <h1>404 - Not Found</h1>
                <p>Ups! no encontramos la página o contenido que buscabas😅</p>
                <Button handleClick={() => navigate('/')} variant="secondary">← Volver al Inicio</Button>
            </section>
        </>
    );
};

export default NotFound;
