import React from 'react';
import styles from './Services.module.scss';
import PostsList from '../../components/postsList/PostsList';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/button/Button';

const Services = () => {
    const navigate = useNavigate();

    return (
        <>
            <section className={styles.hero}>
                <h1>Nuestros servicios</h1>
                <p>
                    La tecnología está creciendo a pasos agigantados y atraviesa
                    todos los aspectos de nuestra vida. Con ALT queremos lograr
                    que un porcentaje de todo eso que pasa en las pantallas
                    tenga un sello TRANS. Te invitamos a conocer nuestros
                    servicios y apoyar el desarrollo tecnológico en manos
                    disidentes.
                </p>
            </section>
            <section className={styles.portfolio}>
                <h2>Diseño UX/UI y Desarrollo Web</h2>
                <p>
                    Podemos diseñar o re-diseñar tu sitio web. Además, tenemos
                    las herramientas para llevarlo a la web con las mejores
                    tecnologías.
                </p>
                <ul className={styles.servicesList}>
                    <li>Landing pages</li>
                    <li>E-commerce</li>
                    <li>Sitios institucionales</li>
                    <li>Portfolios</li>
                    <li>Sitios autoadministrables (CMS)</li>
                    <li>1 año de hosting incluído...</li>
                </ul>
                <Button
                    handleClick={() => navigate('/contacto')}
                    variant="secondary"
                    customClassName={styles.cta}
                >
                    ¡QUIERO UN SITIO!
                </Button>
            </section>
            <section className={styles.projects}>
                <h2>Galería de proyectos</h2>
                <PostsList categoryName={'proyectos'} />
            </section>
            <section className={styles.education}>
                <h2>Cursos y talleres</h2>
                <p>
                    Está en el ADN de una cooperativa el enseñar y compartir la
                    información. Lo hacemos con dedicación, y con la idea de que
                    compartir lo que sabemos nos hace crecer a todes. Por ello,
                    brindamos formaciones sobre diseño, desarrollo web y
                    tecnologías web en general.
                </p>
                <p>
                    <strong>Cursos que hemos creado y facilitado:</strong>
                </p>
                <ul>
                    <li>
                        2021:"Desarrollo Web FullStack desde cero" |
                        Ministerio de Trabajo, Empleo y Seguridad Social
                    </li>
                    <li>
                        2022: "Introducción al diseño UX/UI y desarrollo
                        web con WordPress" | Asociación Civil y Cultural Brandon
                    </li>
                    <li>
                        2022: "Desarrolla y autogestiona tu e-commerce con WordPress" | FLACSO
                    </li>
                </ul>
            </section>
            <section>
                <h2>Ilustración Digital y Community Manager</h2>
                <p>
                    Consultanos por nuestros servicios para llevar al máximo tu
                    proyecto.
                </p>
                <Button
                    handleClick={() => navigate('/contacto')}
                    variant="outline"
                >
                    HABLEMOS
                </Button>
            </section>
        </>
    );
};

export default Services;
