import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import styles from './SinglePost.module.scss';
import { formatDate } from '../../lib/utils';
import { GET_POST_BY_SLUG } from '../../lib/constants';
import Button from '../../components/button/Button';
import Loader from '../../components/loader/Loader';
import NotFound from '../notFound/NotFound';

const SinglePost = () => {
    const navigate = useNavigate();

    const { postSlug } = useParams();

    const { loading, error, data } = useQuery(GET_POST_BY_SLUG, {
        variables: {
            slug: postSlug,
        },
    });

    if (loading) return (
        <section className={styles.loadingContent}>
            <Loader />
        </section>
    );

    if (error)
        return (
            <section>
                <p>Ups 😥...</p>
            </section>
        );

    const postsFound = Boolean(data?.post);

    if (!postsFound) {
        return <NotFound />;
    }

    const { date, title, content, categories } = data.post;

    const postCategory = categories.nodes[0].name;

    return (
        <>
            <section className={styles.post}>
                <h1>{title}</h1>
                <div
                    dangerouslySetInnerHTML={{ __html: content }}
                />
                <div className={styles.postData}>
                    {postCategory === 'proyectos' ? (
                        ''
                    ) : (
                        <p className={styles.date}>{formatDate(date)}</p>
                    )}
                    <Button
                        handleClick={() => navigate(-1)}
                        variant="outline"
                    >
                        ← Volver
                    </Button>
                </div>
            </section>
        </>
    );
};

export default SinglePost;
