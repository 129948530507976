import React from 'react';
import styles from './Button.module.scss';

const Button = ({ children, variant = 'primary', handleClick, type = 'button', disabled, customClassName }) => {
    return (
        <button
            className={`${styles.button} ${styles[variant]} ${customClassName ? customClassName : ''}`}
            onClick={handleClick}
            disabled={disabled}
            type={type}
        >
            {children}
        </button>
    );
};

export default Button;